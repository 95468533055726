import { SlMagicWand } from 'react-icons/sl';

const AssistantButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-4 left-4 z-50 bg-orange-200  rounded-full p-2 shadow-lg focus:outline-none focus:ring-2 "
      aria-label="AI Assistant">
      <div className='flex flex-row'>
        <SlMagicWand className="text-xl ml-1" /> 
        <span className='pl-2 mr-1'> ИИ помощник</span>
      </div>
    </button>
  );
};

export default AssistantButton;
