import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { selectBranchId } from '../store/slices/branchSlice';

const TestPage = () => {
  const [userPrompt, setUserPrompt] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const slug = window.location.pathname.split('/')[1]; // Извлекаем slug ресторана из URL
  const branchId = useSelector(selectBranchId);

  useEffect(() => {
    // Извлекаем переписку из localStorage при загрузке страницы
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    setChatHistory(savedChats[slug] || []);
  }, [slug]);

  function sendPrompt() {
    const newMessage = { role: 'user', content: userPrompt };

    // Обновляем переписку в состоянии
    const updatedChatHistory = [...chatHistory, newMessage];
    setChatHistory(updatedChatHistory);

    // Сохраняем переписку в localStorage
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    savedChats[slug] = updatedChatHistory;
    localStorage.setItem('chats', JSON.stringify(savedChats));

    // Отправляем запрос на сервер
    axios
      .get(`${process.env.REACT_APP_URL}/api/getrecomendation`, {
        params: { branchId: branchId, userPrompt },
      })
      .then((response) => {
        const aiResponse = { role: 'assistant', content: response.data.recommendation };

        // Обновляем переписку с ответом от AI
        const newChatHistory = [...updatedChatHistory, aiResponse];
        setChatHistory(newChatHistory);

        // Сохраняем обновлённую переписку в localStorage
        savedChats[slug] = newChatHistory;
        localStorage.setItem('chats', JSON.stringify(savedChats));
        console.log(response.data);
        
      })
      .catch((e) => {
        console.error('Произошла ошибка', e);
      });

    // Очищаем поле ввода
    setUserPrompt('');
  }

  function clearChat() {
    // Очищаем переписку текущего филиала
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    delete savedChats[slug]; // Удаляем переписку текущего филиала
    localStorage.setItem('chats', JSON.stringify(savedChats));
    setChatHistory([]); // Обновляем состояние
  }

  function trimChatByCharacters(chatHistory, maxCharacters = 10000) {
    let totalCharacters = chatHistory.reduce((acc, message) => acc + message.content.length, 0);
  
    while (totalCharacters > maxCharacters && chatHistory.length > 1) {
      totalCharacters -= chatHistory[0].content.length;
      chatHistory.shift(); // Удаляем самое старое сообщение
    }
  
    return chatHistory;
  }
  

  return (
    <>
      <div>
        {chatHistory.map((message, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <strong>{message.role === 'user' ? 'Вы' : 'AI'}:</strong>
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        ))}
      </div>
      <textarea
        style={{ border: '2px solid' }}
        value={userPrompt}
        onChange={(e) => setUserPrompt(e.target.value)}
      ></textarea>
      <button onClick={sendPrompt}>Отправить</button>
      <button onClick={clearChat} style={{ marginLeft: '10px' }}>
        Очистить диалог
      </button>
    
    </>
  );
};

export default TestPage;
