import React from 'react'
import Stories from 'react-insta-stories'
import { MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { selectColorMainBackground } from '../store/slices/branchSlice'

const StoriesViewer = ({ stories, onClose }) => {
	//const formattedStories = stories.flatMap(group => group.map(story => {
	const colors = useSelector(selectColorMainBackground) || '#FFFFFF'
	// console.log('~ 🥣 colors.fontForTitleStorySize: ', colors.fontForTitleStorySize);
	const opacityDiv = 1 - Number(colors.opacityDivTitleAndTextStory) / 100
	// console.log('~ 🥓 opacityDiv: ', opacityDiv);
	const formattedStories = stories.map(story => {
		// Для обычных изображений без фонового режима
		return {
			content: ({ close }) => (
				<div
					className='div-storyviewer-1'
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'relative', // Указываем, что позиционирование дочерних элементов будет относительно этого контейнера
						// временный блок, цвет задается харкодом
						//background: 'black', //'#44131f',
						background: '-webkit-linear-gradient(46deg, #000000,#1e0a13)',
						background: 'linear-gradient(46deg, #000000,#1e0a13)',
					}}
				>
					{/* картинка конкретного сториса */}
					{/*  <img
          className='img-storyviewer-2'
            //src={`${process.env.REACT_APP_URL}${story.url_image_story}`}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          /> */}
					{/* 					<div
						className='div-storyviewer-3'
						style={{
							backgroundColor: colors.colorDivTitleAndTextStory,
							//opacity: opacityDiv,
							position: 'absolute', // Заголовок и подзаголовок абсолютно позиционированы
							bottom: 0, // Размещаем текст в нижней части изображения
							left: 0,
							width: '100%', // Ширина контейнера текста соответствует ширине изображения
							padding: '10px', // Отступы внутри контейнера текста
							color: 'red', // Цвет текста
							textAlign: 'center', // Выравнивание текста по центру
						}}
					> */}
					<div className='flex justify-center'>
						<h1
							className='h-storyviewer-title-4 text-4xl sm:text-2xl md:text-5xl lg:text-7xl pl-5 pr-5'
							style={{
								fontFamily: colors.fontForTitleStory,
								whiteSpace: 'pre-line',

								/* fontSize: `clamp(1.5rem, 5vw, 3rem)`, // Адаптивный размер */
								//fontSize: `${colors.fontForTitleStorySize}px`,
								fontWeight: colors.fontForTitleStoryWeight,
								fontStyle: colors.fontForTitleStoryStyle,
								// временный блок, цвет задается харкодом
								color: '#c8bcbf',
								//color: colors.colorFontTitleStory,
							}}
						>
							{/* заголовок сториса */}
							{story.title}
						</h1>
					</div>
					<div className='flex justify-center'>
						<p
							className='p-storyviewer-text-5 text-base sm:text-base md:text-lg lg:text-xl mt-5 pl-5 pr-5'
							style={{
								//fontFamily: colors.fontForTextStory,
								//color: colors.colorFontTextStory,
								// временный блок, цвет задается харкодом
								color: '#c8bcbf',
								/* fontSize: `clamp(1.5rem, 3vw, 1.5rem)`, // Адаптивный размер */
								//fontSize: `${colors.fontForTextSize}px`,
								fontWeight: colors.fontForTextWeight,
								fontStyle: colors.fontForTextStyle,
								whiteSpace: 'pre-line',
							}}
						>
							{/* текст сториса */}
							{story.text}
						</p>
					</div>
				</div>
				/* 	</div> */
			),
			duration: story.duration,
		}
	})
	//)
	return (
		<div className='storiesViewerContainer'>
			<div
				className='p-storyviewer-6 bg-slate-300 rounded-full w-6 h-6 flex justify-center items-center'
				onClick={onClose}
				style={{ position: 'absolute', top: 20, right: 20, zIndex: 1010 }}
			>
				<MdClose className='p-storyviewer-close-7' />
			</div>
			<Stories
				stories={formattedStories}
				defaultInterval={1500}
				width='100%'
				height='100%'
			/>
		</div>
	)
}

export default StoriesViewer
