const ModalAssistant = ({ isOpen, onClose, children, colorBg }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      {/* Кнопка закрытия */}
      <div className="bg-slate-600">
        <button onClick={onClose} className="absolute top-10 right-5 text-slate-100  ">
          ✖
        </button>
      </div>
      <div className=" rounded-lg shadow-lg w-11/12 md:w-1/2 max-h-[85vh] overflow-y-auto p-2 relative"
      style={{backgroundColor: colorBg}}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalAssistant;
