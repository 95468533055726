import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  dishes: [],
  status: 'idle',
  error: null,
};

// Async thunk for fetching menu by branch link
// запрос настроек филиала по ссылке
export const fetchMenuByBranchLink = createAsyncThunk(
  'dishes/fetchMenuByBranchLink',
  async (branchLink) => {
    // console.log('~ 🍗 fetchMenuByBranchLink ');
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/menu/${branchLink}`);
    return response.data;
  }
);





const dishesSlice = createSlice({
  name: 'dishes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuByBranchLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenuByBranchLink.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log('~ 🎆 action.payload: ', action.payload);
        state.dishes = action.payload;
      })
      .addCase(fetchMenuByBranchLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default dishesSlice.reducer;

// Selectors
export const selectAllDishes = (state) => state.dishes.dishes;
export const selectDishStatus = (state) => state.dishes.status;