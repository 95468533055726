import { ThemeProvider } from './contexts/ThemeProvider';
import MainPage from './pages/MainPage';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import FeedbackPage from './pages/FeedbackPage';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store/index';

import PaymentPage from './pages/PaymentPage';
import TipsPage from './pages/TipsPage';
import React, { useEffect } from 'react';
import DirectFeedbackPage from './pages/DirectFeedbackPage';
import DeliveryPage from './pages/DeliveryPage';
import OrderDeliveryPage from './pages/OrderDeliveryPage';
import DeliveryEndPage from './pages/DeliveryEndPage';
import NotFoundPage from './pages/NotFoundPage';
import FeedbackThanksPage from './pages/FeedbackThanksPage';



//import { persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { loadCartFromLocalStorage } from './store/slices/cartDeliverySlice';
import TestPage from './pages/TestPage';
import Platforms from './Components/Platforms';
import WebFont from 'webfontloader';
import { selectGoToFeedback } from './store/slices/branchSlice';

function App() {

  const getBranchLinkFromUrl = () => {
    const pathArray = window.location.pathname.split('/');
    const branchLink = pathArray[1]; // Предполагая, что link следует непосредственно за доменом
    return branchLink;
  };
  
  
  useEffect(() => {
    const loadCustomCss = async () => {
      try {
        const branchLink = getBranchLinkFromUrl();
        const response = await fetch(`${process.env.REACT_APP_URL}/custom-css/${branchLink}`);
        const css = await response.text();
        const style = document.createElement('style');
        style.innerHTML = css;
        document.head.appendChild(style);
      } catch (error) {
        console.error('Ошибка при загрузке кастомного CSS:', error);
      }
    };
  
    loadCustomCss();
  }, []);
  



  const designSettings = useSelector(selectGoToFeedback);

  useEffect(() => {
    if (designSettings && designSettings.primaryFont && designSettings.secondaryFont) {
      WebFont.load({
        google: {
          families: [designSettings.primaryFont, designSettings.secondaryFont],
          //families: ['Nunito:200,400,500,900'],
        },
        loading: function() {
          // Скрыть текст или показать загрузочный индикатор
          document.body.style.visibility = 'hidden';
        },
        active: function() {
          // Показать текст, когда шрифты загружены
          document.body.style.visibility = 'visible';
        }
      });
    }
  }, [designSettings?.primaryFont, designSettings?.secondaryFont]);
  


  return (
    <ThemeProvider>
      <Routes>
        {/* <Route path="/:branchLink/feedback/:tableId" element={<DirectFeedbackPage />} /> */}
        <Route path="/:branchLink" element={<MainPage />}></Route>
        <Route path="/:branchLink/feedback" element={<DirectFeedbackPage />}></Route>
        <Route path="/:branchLink/payment" element={<PaymentPage />}></Route>
        <Route path="/:branchLink/tips" element={<TipsPage />}></Route>
        <Route path="/:branchLink/delivery" element={<DeliveryPage />}></Route>
        <Route path="/:branchLink/orderdelivery" element={<OrderDeliveryPage />}></Route>
        <Route path="/:branchLink/deliveryend" element={<DeliveryEndPage />}></Route>
        <Route path="/notfoundpage" element={<NotFoundPage />}></Route>
        <Route path="/feedbackthanks" element={<FeedbackThanksPage />}></Route>
        <Route path="/platforms" element={<Platforms />}></Route>
        <Route path="/:branchLink/test" element={<TestPage />}></Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
