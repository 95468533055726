import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { selectBranchId, selectColorMainBackground } from '../store/slices/branchSlice';

const AssistantDialogue = ({ onClose }) => {
  const [userPrompt, setUserPrompt] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const slug = window.location.pathname.split('/')[1]; // Извлекаем slug ресторана из URL
  const branchId = useSelector(selectBranchId);

  const messagesEndRef = useRef(null);

  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  useEffect(() => {
    // Извлекаем переписку из localStorage при загрузке страницы
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    setChatHistory(savedChats[slug] || []);
  }, [slug]);

  function sendPrompt() {
    const newMessage = { role: 'user', content: userPrompt };

    if (userPrompt === '') {
      return;
    }

    // Обновляем переписку в состоянии
    const updatedChatHistory = [...chatHistory, newMessage];
    setChatHistory(updatedChatHistory);

    // Сохраняем переписку в localStorage
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    savedChats[slug] = updatedChatHistory;
    localStorage.setItem('chats', JSON.stringify(savedChats));

    // Отправляем запрос на сервер
    // Отправляем запрос на сервер
    axios
      .get(`${process.env.REACT_APP_URL}/api/getrecomendation`, {
        params: {
          branchId: branchId,
          userPrompt,
          chatHistory: JSON.stringify(updatedChatHistory), // Передаём актуальную историю
          slug,
        },
      })
      .then((response) => {
        const aiResponse = { role: 'assistant', content: response.data.recommendation };

        // Добавляем ответ AI в chatHistory
        const newChatHistory = [...updatedChatHistory, aiResponse];
        setChatHistory(newChatHistory);

        // Сохраняем обновлённый chatHistory в localStorage
        savedChats[slug] = newChatHistory;
        localStorage.setItem('chats', JSON.stringify(savedChats));
      })
      .catch((e) => {
        console.error('Произошла ошибка', e);
      });

    // Очищаем поле ввода
    setUserPrompt('');
  }

  function clearChat() {
    // Очищаем переписку текущего филиала
    const savedChats = JSON.parse(localStorage.getItem('chats')) || {};
    delete savedChats[slug]; // Удаляем переписку текущего филиала
    localStorage.setItem('chats', JSON.stringify(savedChats));
    setChatHistory([]); // Обновляем состояние
  }

  function trimChatByCharacters(chatHistory, maxCharacters = 10000) {
    let totalCharacters = chatHistory.reduce((acc, message) => acc + message.content.length, 0);

    while (totalCharacters > maxCharacters && chatHistory.length > 1) {
      totalCharacters -= chatHistory[0].content.length;
      chatHistory.shift(); // Удаляем самое старое сообщение
    }

    return chatHistory;
  }

  return (
    <>
      <div
        className="flex flex-col h-4/6 w-full  rounded shadow-lg"
        style={{ backgroundColor: colors.colorMainBackground }}>
        {/* Фиксированное сообщение */}
        <p className="border-t p-2 text-sm" style={{ color: colors.colorFontFeedback }}>
          Я Ваш ассистент помощник по выбору блюд из этого меню, задайте мне вопрос, и я постараюсь
          вам ответить 😎
        </p>
        {/* Переписка */}
        <div
          ref={messagesEndRef}
          className="flex-1 overflow-y-auto p-4"
          style={{ color: colors.colorFontFeedback }}>
          {chatHistory.map((message, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <strong>{message.role === 'user' ? 'Вы' : 'AI'}:</strong>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </div>
          ))}
        </div>

        {/* Поле ввода и кнопки */}
        <div ref={messagesEndRef} className="border-t p-2">
          <textarea
            style={{
              backgroundColor: colors.colorEditsFeedback,
              borderColor: colors.colorEditsBorderFeedback,
              borderRadius: `${colors.allInputsBorderRadius}px`,
              color: colors.colorFontInputFeedback,
            }}
            placeholder="Например: Посоветуй сытное блюдо"
            className="textarea-ai-dialogue w-full p-2  rounded-md mb-2"
            rows={4}
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}></textarea>
          <div className="flex flex-row justify-between">
            <button
              onClick={onClose}
              className="bg-slate-200 text-slate-800 px-1 py-1 rounded"
              style={{
                backgroundColor: colors.colorButtonFeedback,
                borderColor: colors.colorButtonBorderFeedback,
                borderWidth: '1px',
                color: colors.colorButtonTextFeedback,
                borderRadius: `${colors.allButtonsBorderRadius}px`,
              }}>
              Свернуть
            </button>
            <button
              className="bg-slate-200 text-slate-800 px-1 py-1 rounded"
              onClick={clearChat}
              style={{
                backgroundColor: colors.colorButtonFeedback,
                borderColor: colors.colorButtonBorderFeedback,
                borderWidth: '1px',
                color: colors.colorButtonTextFeedback,
                borderRadius: `${colors.allButtonsBorderRadius}px`,
              }}>
              Очистить диалог
            </button>
            <button
              className="bg-slate-200 text-slate-800 px-1 py-1 rounded"
              onClick={sendPrompt}
              style={{
                backgroundColor: colors.colorButtonFeedback,
                borderColor: colors.colorButtonBorderFeedback,
                borderWidth: '1px',
                color: colors.colorButtonTextFeedback,
                borderRadius: `${colors.allButtonsBorderRadius}px`,
              }}>
              Отправить
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssistantDialogue;
