import React, { useCallback, useMemo, useRef, lazy, Suspense } from 'react';
import Header from '../Components/Header';
import SearchInput from '../Components/SearchInput';
//import Card from '../Components/Card';

import LogoHeader from '../Components/LogoHeader';
import { useEffect, useState, useContext } from 'react';

import { useLocation, useParams, useNavigate } from 'react-router-dom';
import CartFooter from '../Components/CartFooter';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../store/slices/cartSlice';
import { selectAllDishes } from '../store/slices/dishesSlice';
import { fetchMenuByBranchLink } from '../store/slices/dishesSlice';

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import AssistantButton from '../Components/AssistantButton';
import ModalAssistant from '../Components/ModalAssistant';
import AssistantDialogue from '../Components/AssistantDialogue';

import {
  fetchBranch,
  selectColorMainBackground,
  selectGoToFeedback,
  selectSubscription_options,
  selectSubscription_state,
  selectUseMenu,
  textabovemenu,
} from '../store/slices/branchSlice';
import { selectDishStatus } from '../store/slices/dishesSlice';
import { checkTableUrlInDB, setTableUrl } from '../store/slices/tableUrlSlice';
import { selectTotalQuantity } from '../store/slices/cartSlice';
import { setBranchLinkGlobal } from '../store/slices/branchLinkGlobal';
import { CiCircleChevUp } from 'react-icons/ci';
import Unavailible from '../Components/Unavailable';
import ExpiredSubscription from '../Components/ExpiredSubscription';
import ModalCard from '../Components/ModalCard';
import DirectFeedbackPage from './DirectFeedbackPage';

import StoryPreview from '../Components/StoryPreview';
import StoriesViewer from '../Components/StoriesViewer';
import { fetchStoriesByBranchLink } from '../store/slices/storiesSlice';
import { fetchTipsRecipients, selectAllTipsRecipients } from '../store/slices/tipsSlice';
import { fetchPayments, selectPayments } from '../store/slices/paymentSlice';
import { addToCart, removeFromCart, selectCart } from '../store/slices/cartSlice';

import store from 'store2';
import {
  fetchBranchOptionsByBranchId,
  fetchBranchOptionsByBranchLink,
} from '../store/slices/branchOptionsSlice';

// Лениво загружаем компонент Card
const Card = lazy(() => import('../Components/Card'));

const MainPage = () => {
  const navigate = useNavigate();
  const [showAssistant, setShowAssistant] = useState(false);

  const goToFeedback = useSelector(selectGoToFeedback);
  const { branchLink } = useParams();
  //const [cardViewVariant, setCardViewVariant] = useState(1);

  //const cardViewVariant = useSelector(selectCardViewVariant);
  //const [activeCategory, setActiveCategory] = useState(null);
  const isDelivery = false;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const cardViewVariant = useSelector((state) => state.viewVariant.cardViewVariant);
  const cartKey = `cart_${branchLink}`;

  //const cart = useSelector((state) => (isDelivery ? state.cartDelivery.items : state.cart.items));
  //const cartKey = 'cart';
  //const cart = useSelector(selectCart) || [];
  const cart =
    useSelector((state) => {
      /* console.log('Cart State: ', state.cart); */
      return selectCart(state);
    }) || [];

  //const storiesItems = useSelector(state => state.stories.items);
  const { loading, items: storiesItems, error } = useSelector((state) => state.stories);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    // Здесь можно добавить логику фильтрации блюд по выбранной категории
  };
  // данные для модальной карточки
  const [modalContent, setModalContent] = useState({});
  //const [tipsRecipients, setTipsRecipients] = useState({});
  const menuRef = useRef(null);

  const pageName = 'menu';
  const dispatch = useDispatch();
  const totalQuantity = useSelector(selectTotalQuantity);
  const [searchTerm, setSearchTerm] = useState('');
  const branchUseMenu = useSelector(selectUseMenu);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const subsState = useSelector(selectSubscription_state);
  const subsOptions = useSelector(selectSubscription_options);
  const textAboveMenu = useSelector(textabovemenu);
  const [showAiButton, setShowAiButton] = useState(false);


  const colors = useSelector(selectColorMainBackground) || '#FFFFFF';
  //const colorDishBackground = useSelector(selectColorDishBackground) || '#FFFFFF';

  const [selectedStories, setSelectedStories] = useState([]);

  const [storiesGroups, setStoriesGroups] = useState([]);
  //const [hasLoadedCart, setHasLoadedCart] = useState(false);

  const hasLoadedCart = useRef(false);

  /*   useEffect(() => {
    console.log(`Компонент MainPage.jsx перерисован 🎨`);
  }); */

  useEffect(() => {
    if (totalQuantity > 0) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  }, [totalQuantity]);

  useEffect(() => {
    // Вызов функции для создания групп сторис и обновление состояния
    if (storiesItems) {
      const groups = createStoriesGroups(storiesItems);
      setStoriesGroups(groups);
    }
  }, [storiesItems]);

  //const { branchLinkGlobal } = useParams();
  if (branchLink === 'bkhotel') {
    navigate(`/${branchLink}/feedback`); // Бронзовый кабан
  }
  if (branchLink === 'bkbath') {
    navigate(`/${branchLink}/feedback`); // Бронзовый кабан
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableUrl = queryParams.get('t');

  useEffect(() => {
    dispatch(setBranchLinkGlobal(branchLink));
    if (tableUrl) {
      dispatch(setTableUrl(tableUrl));
    }
    if (tableUrl) {
      dispatch(checkTableUrlInDB(tableUrl));
    }
  }, [tableUrl, dispatch]);

  const dishes = useSelector(selectAllDishes);
  const [showFooter, setShowFooter] = useState(true);
  const dishStatus = useSelector(selectDishStatus);
  const tipsRecipients = useSelector(selectAllTipsRecipients);
  const paymentLink = useSelector(selectPayments);

  const saveToLocalStorage = (data, key) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const loadCartFromLocalStorage = (key) => {
    const savedCart = localStorage.getItem(key);
    return savedCart ? JSON.parse(savedCart) : [];
  };

  // Load cart from localStorage on mount
  useEffect(() => {
    if (!hasLoadedCart.current) {
      /* console.log('1️⃣ useEffect > loadCartFromLocalStorage'); */
      const savedCart = store.get(cartKey, []);
      /* console.log('Loaded cart from localStorage: ', savedCart); */
      if (savedCart.length > 0) {
        savedCart.forEach((item) => dispatch(addToCart({ ...item, quantity: item.quantity })));
      }
      hasLoadedCart.current = true; // Устанавливаем флаг после первой загрузки
    }
  }, [dispatch]);

  // Save cart to localStorage on change

  useEffect(() => {
    /* console.log('Saving cart to localStorage: ', cart); */
    store.set(cartKey, cart);
    /* console.log('2️⃣ useEffect > saveToLocalStorage'); */
  }, [cart]);

  const handleAddToCart = useCallback(
    (dish) => {
      /*  console.log('3️⃣ MainPage handleAddToCart'); */
      setShowFooter(true);
      dispatch(addToCart({ ...dish, quantity: 1 }));
    },
    [dispatch],
  );

  const handleRemoveFromCart = useCallback(
    (dish) => {
      /* console.log('Removing item:', dish); */
      dispatch(removeFromCart({ ...dish }));
    },
    [dispatch],
  );

  const handleClearCart = () => {
    /* console.log('Clearing cart'); */
    localStorage.removeItem(cartKey);
    dispatch(clearCart());
    setTimeout(() => {
      setShowFooter(false);
    }, 1000);
  };

  const removeFromLocalStorage = (item) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    const index = cart.findIndex((cartItem) => cartItem.id === item.id);
    if (index !== -1) {
      cart[index].quantity += item.quantity;
      if (cart[index].quantity <= 0) {
        cart.splice(index, 1);
      }
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  };

  //const currentQuantity = 10;

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  // Мемоизация groupedDishes
  const groupedDishes = useMemo(() => {
    return dishes.reduce((acc, dish) => {
      if (!acc[dish.category_name]) {
        acc[dish.category_name] = {
          dishes: [],
          position: dish.category_position,
        };
      }
      acc[dish.category_name].dishes.push(dish);
      return acc;
    }, {});
  }, [dishes]);

  // 2. Отсортировать группы на основе category_position
  /*   const sortedCategories = Object.keys(groupedDishes).sort(
    (a, b) => groupedDishes[a].position - groupedDishes[b].position,
  ); */
  // Мемоизация sortedCategories
  const sortedCategories = useMemo(() => {
    return Object.keys(groupedDishes).sort(
      (a, b) => groupedDishes[a].position - groupedDishes[b].position,
    );
  }, [groupedDishes]);

  const memoizedSearchTerm = useMemo(() => searchTerm, [searchTerm]);

  // Мемоизация filteredDishes для каждой категории
  const memoizedFilteredDishes = useMemo(() => {
    const result = {};
    sortedCategories.forEach((category) => {
      result[category] = groupedDishes[category].dishes
        .sort((a, b) => a.position - b.position)
        .filter((dish) => {
          const searchTermLower = memoizedSearchTerm.toLowerCase();
          return (
            dish.menuoption !== 3 &&
            dish.menuoption !== 4 &&
            (dish.title?.toLowerCase().includes(searchTermLower) ||
              category.toLowerCase().includes(searchTermLower) ||
              dish.description?.toLowerCase().includes(searchTermLower) ||
              (dish.tags &&
                dish.tags.some((tag) => tag.description?.toLowerCase().includes(searchTermLower))))
          );
        });
    });
    return result;
  }, [groupedDishes, sortedCategories, memoizedSearchTerm]);

  useEffect(() => {
    Events.scrollEvent.register('end', (to, element) => {
      setActiveCategory(to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('end');
    };
  }, []);

  const [activeCategory, setActiveCategory] = useState(sortedCategories[0]);

  useEffect(() => {
    const activeMenuItem = document.querySelector(`.menu-item[data-category="${activeCategory}"]`);
    /*    console.log('activeMenuItem: ', activeMenuItem);
    console.log('menuRef.current: ', menuRef.current);
    console.log('activeCategory: ', activeCategory); */

    if (activeMenuItem) {
      activeMenuItem.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [activeCategory]);

  const scrollToCategory = (category) => {
    const element = document.getElementById(`category-${category}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleButtonTest = () => {
    navigate(`/${branchLink}/test`);
  };

  /*   const handleCategoryClick = (category) => {
    const element = document.getElementById(`category-${category}`);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
      setActiveCategory(category);
    }
  };
 */

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    scroller.scrollTo(`category-${category}`, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -50,
    });

    const activeMenuItem = document.querySelector(`.menu-item[data-category="${category}"]`);
    if (activeMenuItem && menuRef.current) {
      activeMenuItem.scrollIntoView({
        behavior: 'auto', // Используем 'auto' для мгновенной прокрутки
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const categoriesData = sortedCategories
      .map((category) => {
        const element = document.getElementById(`category-${category}`);
        if (element) {
          const top = element.offsetTop;
          const bottom = top + element.offsetHeight;
          return { category, top, bottom };
        }
        return null;
      })
      .filter(Boolean);

    const activeCategory = categoriesData.find(
      (item) => scrollPosition >= item.top && scrollPosition < item.bottom,
    )?.category;

    setActiveCategory(activeCategory || null);

    if (activeCategory && menuRef.current) {
      const activeCategoryElement = menuRef.current.querySelector(
        `.horizontal-category[data-category="${activeCategory}"]`,
      );
      if (activeCategoryElement) {
        activeCategoryElement.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sortedCategories]);

  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(fetchMenuByBranchLink(branchLink));
    }
    dispatch(fetchBranch(branchLink));
    dispatch(fetchBranchOptionsByBranchLink(branchLink));

    if (storiesItems.length === 0) {
      dispatch(fetchStoriesByBranchLink(branchLink));
    }
    // запрос чаевых
    if (tipsRecipients.length === 0) {
      dispatch(fetchTipsRecipients(branchLink));
    }
    // запрос ссылок на оплату
    if (paymentLink === '') {
      dispatch(fetchPayments(branchLink));
    }
  

  }, [dispatch, branchLink]);

  const tableExists = useSelector((state) => state.tableUrl.exists); // Берем из слайса если такой столик? И передаем его в пропсы
   const useAi = useSelector(
    (state) => state.branchOptions.use_ai_main_menu,
    (prev, next) => prev === next
  ); 
  //const aiMainMenu = useSelector(useAiMainMenu)
 // const useAi = useSelector((state) => state.branchOptions.use_ai_main_menu);
//  console.log(`😝 useAi = ${useAi}`);
  //console.log('привет ');

  if (dishStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (dishStatus === 'failed') {
    return <div className="text-center pt-10">Такой страницы не существует!</div>;
  }

  // подписка истекла
  if (subsState === 0) {
    return <ExpiredSubscription />;
  }

  if (!branchUseMenu) {
    if (goToFeedback?.goToFeedback) {
      return <DirectFeedbackPage></DirectFeedbackPage>;
    }
  }
  if (!branchUseMenu) {
    return (
      <div className={` h-screen`}>
        <LogoHeader></LogoHeader>
        <Header />
        <Unavailible></Unavailible>
      </div>
    );
  }

  // тариф только отзывы. Остальное отключаем
  if (subsOptions <= 1) {
    return <Unavailible text={'Страница недоступна'} />;
  }

  const handleOpenModal = (data) => {
    setModalContent(data); // Сохраняем данные для модального окна
    setIsModalOpen(true); // Открываем модальное окно
  };

  function createStoriesGroups(stories) {
    const groups = [];

    stories.forEach((story) => {
      if (story.display_location === 1 || story.display_location === 2) {
        groups.push(story);
      }
    });

    return groups;
  }

  /*   function getCategories() {
    console.log('sortedCategories: ', sortedCategories);
  } */

  const handleAssistantClick = () => {
    setShowAssistant(true);
  };

  const handleCloseModalAssistant = () => {
    setShowAssistant(false);
  };

  /////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  return (
    <>
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="btn-mainpage-up-2 fixed bottom-12 right-2  text-white rounded ">
        <CiCircleChevUp className="icon-mainpage-up-3 text-3xl text-slate-500" />
      </button>

      {useAi && (
        <>
          <AssistantButton onClick={handleAssistantClick} />

          <ModalAssistant isOpen={showAssistant} onClose={handleCloseModalAssistant} colorBg={colors.colorMainBackground}>
            <AssistantDialogue onClose={handleCloseModalAssistant} />
          </ModalAssistant>
        </>
      )}
      {/* главный фон меню */}
      <div
        className="pb-20 main-page max-h-max min-h-screen"
        style={{ backgroundColor: colors.colorMainBackground }}>
        <LogoHeader></LogoHeader>
        <Header
          pageName={pageName}
          bgColor={colors.colorTopMainMenu}
          colorFontMainMenu={colors.colorFontMainMenu}
        />

        {/* СТОРИСЫ */}
        <div className="div-mainpage-4 pt-2 flex overflow-x-auto">
          <div className="div-mainpage-5 pl-1 stories-preview-container flex flex-row gap-2">
            {storiesGroups.map((story, index) => (
              <div
                className="div-mainpage-6"
                key={index}
                style={{
                  /* maxWidth: `${colors.groupStoriesWidth}px`,  */
                  /* flexBasis: '120px',  */
                  flexGrow: 1,
                }}>
                <StoryPreview
                  content={story.contents}
                  profileImage={story.profileImg}
                  onClick={() => setSelectedStories([story.contents])}
                  colorStoriesGroupBorder={colors.colorStoriesGroupBorder}
                  colorFontGroupStories={colors.colorFontGroupStories}
                  groupStoriesWidth={colors.groupStoriesWidth}
                  groupStoriesHeight={colors.groupStoriesHeight}
                  groupStoriesBorderRadius={colors.groupStoriesBorderRadius}
                  groupStoriesBorderRadiusSize={colors.groupStoriesBorderRadiusSize}
                />
                <div
                  className="div-mainpage-7 story-title text-center"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    color: colors.colorFontGroupStories,
                    fontFamily: colors.fontForTitleGroupStoies,
                    fontSize: `${colors.fontForTitleGroupStoriesSize}px`,
                    fontWeight: colors.fontForTitleGroupStoriesWeight,
                    fontStyle: colors.fontForTitleGroupStoriesStyle,
                  }}>
                  <span className="span-mainpage-8">{story.title}</span>
                </div>
              </div>
            ))}
          </div>
          {selectedStories.length > 0 && (
            <StoriesViewer
              stories={selectedStories[0]}
              onClose={() => setSelectedStories([])}
              colorFontTitleStory={colors.colorFontTitleStory}
            />
          )}
        </div>

        <SearchInput setSearchTerm={setSearchTerm} />
        {!isModalOpen && (
          <div
            className="sticky-menu"
            ref={menuRef}
            style={{ backgroundColor: colors.colorBackgroundHorizontalMenu }}>
            <ScrollMenu>
              {sortedCategories.map((category) => (
                <Link
                  key={category}
                  to={`category-${category}`}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => handleCategoryClick(category)}>
                  <div
                    className={`menu-item category-${category}`}
                    data-category={category}
                    style={{
                      backgroundColor: colors.colorCategoryHorizontal,
                      borderRadius: `${colors.horizCategoryBorderRadius}px`,
                      color: colors.colorFontCategoryHorizontal,
                      fontSize: `${colors.fontForHorizontalCategorySize}px`,
                      fontFamily: colors.fontForHorizontalCategory,
                      fontWeight: colors.fontForHorizontalCategoryWeight,
                      fontStyle: colors.fontForHorizontalCategoryStyle,
                      borderColor: colors.colorBorderCategoryHorizontal,
                      borderWidth: `${colors.horizCategoryBorderSize}px`,
                    }}>
                    <span className="menu-item-span menu-item-span-main">{category}</span>
                  </div>
                </Link>
              ))}
            </ScrollMenu>
          </div>
        )}
        <div
          className="div-mainpage-9 p-1"
          style={{
            color: colors.colorFontTextAboveMenu,
            fontFamily: colors.fontForTextAboveMenu,
            fontWeight: `${colors.fontForTextAboveMenuWeight}`,
            fontSize: `${colors.fontForTextAboveMenuSize}px`,
            fontStyle: `${colors.fontForTextAboveMenuStyle}`,
          }}>
          {textAboveMenu}
        </div>

        {/* Горизонтальоне меню */}
        <div className="content horizontal-menu">
          <div className="div-mainpage-11">
            {sortedCategories.map((category) => {
              const filteredDishes = memoizedFilteredDishes[category];

              const categoryDescription = groupedDishes[category].dishes[0]?.category_description;

              // Если в категории нет блюд после фильтрации и категория не соответствует поисковому запросу, пропустить отображение категории
              if (
                filteredDishes.length === 0 &&
                !category.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return null;
              }

              return (
                /* общий фон под блюдом */

                <Element name={`category-${category}`} key={category}>
                  <div
                    style={{ backgroundColor: colors.colorDishBackground }}
                    className="div-mainpage-12 dish-back pb-2"
                    key={category}
                    id={`category-${category}`}>
                    {isModalOpen && <ModalCard onClose={handleCloseModal} data={modalContent} />}
                    <div
                      style={{
                        backgroundColor: colors.colorCategoryMenu,
                        color: colors.colorFontCategory,
                        fontFamily: colors.fontForCategory,
                        fontSize: `${colors.fontForCategorySize}px`,
                        fontWeight: `${colors.fontForCategoryWeight}`,
                        fontStyle: `${colors.fontForCategoryStyle}`,
                      }}
                      className="div-mainpage-13 category-menu text-xl text-center   py-1">
                      <span> {category}</span>
                    </div>
                    {categoryDescription && (
                      <div
                        style={{
                          backgroundColor: colors.colorCategoryDescriptionMenu,
                          color: colors.colorFontCategoryDescription,
                          fontFamily: colors.fontForCategoryDescription,
                          fontSize: `${colors.fontForCategoryDescriptionSize}px`,
                          fontWeight: `${colors.fontForCategoryDescriptionWeight}`,
                          fontStyle: `${colors.fontForCategoryDescriptionStyle}`,
                        }}
                        className="div-mainpage-14 category-desc text-sm text-center  py-1">
                        <span> {categoryDescription}</span>
                      </div>
                    )}
                    <div
                      className={
                        cardViewVariant === 0
                          ? 'flex flex-col mx-2 view-list '
                          : 'div-mainpage-15 grid mx-2 grid-cols-2 sm:grid-cols-3 md:grid-col-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-1'
                      }>
                      {filteredDishes.map((dish) => (
                        <Suspense fallback={<div>Загружаем блюда...</div>} key={dish.id}>
                          <Card
                            cardViewVariant={cardViewVariant}
                            key={dish.id}
                            {...dish}
                            setShowFooter={setShowFooter}
                            tableExists={tableExists}
                            onAddToCart={handleAddToCart}
                            bgColor={colors.colorDishCard}
                            colorFontTitle={colors.colorFontDishTitle}
                            colorOrderButtonPlus={colors.colorOrderButtonPlus}
                            colorFontDishDescription={colors.colorFontDishDescription}
                            colorFontDishPrice={colors.colorFontDishPrice}
                            colorFontDishOutput={colors.colorFontDishOutput}
                            colorDishCardBorder={colors.colorDishCardBorder}
                            colorFontDishOrder={colors.colorFontDishOrder}
                            colorFontDishOrderCount={colors.colorFontDishOrderCount}
                            fontForDishCardTitle={colors.fontForDishCardTitle}
                            fontForDishCardDescription={colors.fontForDishCardDescription}
                            fontForDishCardOutput={colors.fontForDishCardOutput}
                            fontForDishCardPrice={colors.fontForDishCardPrice}
                            fontForDishCardTitleSize={colors.fontForDishCardTitleSize}
                            fontForDishCardTitleWeight={colors.fontForDishCardTitleWeight}
                            fontForDishCardTitleStyle={colors.fontForDishCardTitleStyle}
                            fontForDishCardDescriptionSize={colors.fontForDishCardDescriptionSize}
                            fontForDishCardDescriptionWeight={
                              colors.fontForDishCardDescriptionWeight
                            }
                            fontForDishCardDescriptionStyle={colors.fontForDishCardDescriptionStyle}
                            fontForDishCardOutputSize={colors.fontForDishCardOutputSize}
                            fontForDishCardOutputWeight={colors.fontForDishCardOutputWeight}
                            fontForDishCardOutputStyle={colors.fontForDishCardOutputStyle}
                            fontForDishCardPriceSize={colors.fontForDishCardPriceSize}
                            fontForDishCardPriceWeight={colors.fontForDishCardPriceWeight}
                            fontForDishCardPriceStyle={colors.fontForDishCardPriceStyle}
                            dishCardBorderRadius={colors.dishCardBorderRadius}
                            dishCardBorderSize={colors.dishCardBorderSize}
                            colorDishCardButtonBorder={colors.colorDishCardButtonBorder}
                            dishCardButtonBorderSize={colors.dishCardButtonBorderSize}
                            dishCardButtonBorderRadius={colors.dishCardButtonBorderRadius}
                            onRemoveFromCart={handleRemoveFromCart}
                            /* currentQuantity={cart.find((item) => item.id === dish.id)?.quantity || 0} */
                            currentQuantity={
                              cart.find((item) => item.id === dish.id)?.quantity || 0
                            }
                          />
                        </Suspense>
                      ))}
                    </div>
                  </div>
                </Element>
              );
            })}

            <style jsx>{`
              .sticky-menu {
                position: sticky;
                top: 0;
                background-color: white;
                /* z-index: 1000; */
                display: flex;
                overflow-x: auto;
                margin-top: 10px;
              }
              .menu-item {
                padding: 7px 5px;
                padding-bottom: 8px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 5px;
                margin-left: 2px;
                cursor: pointer;
                white-space: nowrap; /* Запрещаем перенос текста */
                overflow: hidden; /* Скрываем переполнение */
                text-overflow: ellipsis; /* Добавляем троеточие для длинного текста */
              }
              .menu-item.active {
                background-color: #ddd;
              }
              .content {
                padding-top: 10px;
              }
            `}</style>
          </div>
        </div>

        {showFooter && (
          <CartFooter
            className="mt-10"
            handleClearCart={handleClearCart}
            isDelivery={isDelivery}
            colorOrderDiv={colors.colorOrderDiv}
            colorOrderDivButton={colors.colorOrderDivButton}
            colorOrderDivButtonBorder={colors.colorOrderDivButtonBorder}
            colorOrderDivFontColor={colors.colorOrderDivFontColor}
            colorOrderDivFontColorButton={colors.colorOrderDivFontColorButton}
            fontForOrderDiv={colors.fontForOrderDiv}
            colorMainBackground={colors.colorMainBackground}
            colorFontBranchName={colors.colorFontBranchName}
            colorSearch={colors.colorSearch}
            allButtonsBorderRadius={colors.allButtonsBorderRadius}
            allInputsBorderRadius={colors.allInputsBorderRadius}
          />
        )}
      </div>
    </>
  );
};

export default MainPage;
